import React from 'react'
import { graphql } from 'gatsby'
import TradePage from './index'

const TradeWines = ({ data }) => {
  return <TradePage data={data} />
}

export default TradeWines

export const query = graphql`
  query TradeWinesPage($locale: String) {
    page: contentfulPage(
      slug: { eq: "trade-wines" }
      node_locale: { eq: $locale }
    ) {
      name
      noindex
      sections {
        ...ContentSectionFragment
      }
    }
  }
`
